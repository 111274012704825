
/* main */
.main {
	background-color: #fc942e;
  background-color: #ffffff;
  min-height: 300px;
  /* width: 100%; */
  transition: all 500ms linear;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-top: 2rem;
}
.main .main--wrapper {
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
	padding: 1.5rem .5rem;
}
.main--wrapper .main--title {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  font-family: Monument Extended;
}
.main--item {
  white-space: nowrap;
  padding-bottom: 1rem;
}
.main--item svg {
  width: 1rem;
  fill: currentColor;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.main--item .main--link {
  text-decoration: underline;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  white-space: nowrap;
  color: #000;
}
.connect--wrapper .connect--socials {
	display: flex;
	justify-content: center;
	/* padding: 1rem 0; */
	text-align: center;
	flex-direction: column;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.connect--item {
  margin-right: 0.5rem !important;
	width: 97px;
  padding-bottom: .5rem;
}
.connect--item a {
  display: flex;
  flex-direction: row;
  color: #000;
}
.connect--link {
	margin-right: 1rem !important;
}
.twitter,
.facebook,
.instagram,
.linkedin {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 auto;
  color: #000000;
}
.sr-only {
  display: none;
}

/* mini screen */
@media screen and (min-width: 460px) {
  .main {
		display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5rem;
    text-align: left;
  }
	.connect--wrapper .connect--socials {
		display: flex;
		justify-content: center;
		/* padding: 1rem 0; */
		text-align: center;
		flex-direction: column;
		align-content: center;
		align-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}
  .connect--wrapper .connect--socials {
    padding: 0;
  }
}
@media screen and (min-width: 650px) {
}
/* Wider screen */
@media screen and (min-width: 981px) {
	.main {
		display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5rem;
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
	}
	.connect--wrapper .connect--socials {
		display: flex;
		justify-content: center;
		/* padding: 1rem 0; */
		text-align: center;
		flex-direction: column;
		align-content: center;
		align-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}
}