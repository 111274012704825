.contact {
  margin: 0;
  padding: 0;
}
.contact--main {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.contact--title {
  line-height: 1.1rem;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-align: center;
}
.contact--heading {
  text-align: center;
  font-size: 1.5rem;
}
.contact--content {
  display: flex;
  flex-direction: column;
}
.contact--form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5rem;
}
.form--container {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  flex-direction: column;
}
.form--validations {
  display: flex;
  justify-content: center;
  /* background-color: #ff0000; */
  color: #ff0000;
  padding: .5rem;
  border-radius: 5px;
}
.form--validations ul, .form--validations li {
  text-align: left;
  list-style-type: disc;
}
.form--required:after {
  color: #FF0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.contact--form .form--item {
  margin: .5rem 0;
  border-radius: 5px;
  width: 100%;
  position: relative;
  text-align: left;
}
.contact--form .form--text {
  width: 100%;
}
.form--label {
  color: #000;
  position: relative;
  height: 16px;
  text-align: left;
  font-size: inherit;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02rem;
}
.form--input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  line-height: 3rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 10px;
  margin-top: 5px;
  width: 100%; 
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}
.form--button {
  width: 100%;
  margin-top: 16px;
  font-size: 1.2rem !important;
  line-height: 3rem;
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #fef3e7;
  background-color: #000000;
  padding-left: 10px;
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.form--button:hover {
  color: #005a87;
}
.contact--support {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  align-content: center;
  align-items: center;
}
.support--item {
  width: 100%;
  padding: 2rem .3rem;
  margin-bottom: 3rem;
  box-shadow: 0 0 1.5px rgb(0 0 0 / 2%), 0 0 3.6px rgb(0 0 0 / 3%), 0 0 6.8px rgb(0 0 0 / 4%), 0 0 12.1px rgb(0 0 0 / 4%), 0 0 22.6px rgb(0 0 0 / 5%), 0 0 54px rgb(0 0 0 / 7%);
}
.support--item:hover {
  box-shadow: 0 2.3px 1.5px rgb(0 0 0 / 2%), 0 5.8px 3.7px rgb(0 0 0 / 3%), 0 11.9px 7.6px rgb(0 0 0 / 4%), 0 24.5px 15.7px rgb(0 0 0 / 5%), 0 67px 43px rgb(0 0 0 / 7%);
  color: inherit;
  color: #005a87;
}
.support--heading {
  text-align: center;
  font-size: 1rem;
}
.contact--address {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.social {
  display: inline-flex;
  flex-wrap:wrap;text-align: center;
}
.social--lists {
  display: flex;
}
.social--content {
  margin: 2rem 1rem;
  padding: 1rem;
  /* border: 1px solid #e2e2e2; */
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.social--content:hover {
  border-radius: 5px;
  box-shadow: 0px 2px 5px #666;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.social--icon {
  width: 2.5em;
  height: 2.5em;
  padding: 0;
  margin: 0.5em 1em;
}
.social--icon_color {
  fill:#AB3838;
}
.social--icon_dark_theme {
  fill:#15202b;
}
@media only screen and (min-width: 415px) {
  .contact {
    margin: 0;
    padding: 0;
  }
  .contact--heading {
    text-align: center;
    color: #000000;
    font-size: inherit;
  }
  .form--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contact--form .form--item {
    margin: .5rem 0;
    border-radius: 5px;
    width: 45%;
    position: relative;
    text-align: left;
  }
  .contact--form .form--text {
    width: 100%;
  }
  .contact--support {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5rem;
  }
  .support--item {
    width: 45%;
    width: 48%;
    padding: 2rem .3rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 1.5px rgb(0 0 0 / 2%), 0 0 3.6px rgb(0 0 0 / 3%), 0 0 6.8px rgb(0 0 0 / 4%), 0 0 12.1px rgb(0 0 0 / 4%), 0 0 22.6px rgb(0 0 0 / 5%), 0 0 54px rgb(0 0 0 / 7%);
  }
  .support--heading {
    text-align: center;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 960px) {
  .contact--main {
    font-size: 1rem;
  }
  .contact--heading {
    text-align: center;
    color: #000000;
    font-size: revert;
  }
  .form--button {
    width: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .contact--support {
    justify-content: space-between;
  }
}