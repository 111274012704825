.platforms--main {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.platforms--title {
  font-size: 28px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 1.5rem;
  display: inline-block;
  position: relative;
}

.platforms--title:after,
.platforms--title:before {
  content: "";
  position: absolute;
  bottom: 0;
}

.platforms--title:before {
  width: 15px;
  height: 15px;
  border: 3px solid #fff;
  background-color: #fc5c0f;
  background-color: #000000;
  left: 50%;
  transform: translatex(-50%);
  bottom: -9px;
  z-index: 9;
  border-radius: 50%;
}

.platforms--title:after {
  height: 2px;
  background-color: rgba(252, 92, 15, 0.46);
  background-color: #000000;
  left: 5%;
  right: 5%;
}
.platforms--wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  padding-top: 2rem;
}
.platform--header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}
a {
  text-decoration: none;
  color: #008dd3;
  transition: all .3s;
}

a:hover {
  color: #006ba0;
}

.platform--card {
  box-shadow: 0 0 1.5px rgb(0 0 0 / 2%), 0 0 3.6px rgb(0 0 0 / 3%), 0 0 6.8px rgb(0 0 0 / 4%), 0 0 12.1px rgb(0 0 0 / 4%), 0 0 22.6px rgb(0 0 0 / 5%), 0 0 54px rgb(0 0 0 / 7%);
  background: #fff;
  text-align: left;
  border-radius: 8px;
  color: #0b2227;
  padding: 3em 2.5em;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  margin-bottom: 2em;
  margin-left: 1.5%;
  margin-right: 1.5%;
  display: flex;
}

.platform--card:hover {
  box-shadow: 0 2.3px 1.5px rgb(0 0 0 / 2%), 0 5.8px 3.7px rgb(0 0 0 / 3%), 0 11.9px 7.6px rgb(0 0 0 / 4%), 0 24.5px 15.7px rgb(0 0 0 / 5%), 0 67px 43px rgb(0 0 0 / 7%);
  color: inherit;
  color: #005a87;
}

.platform--algoboard--tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5em;
  width: 100%;
  background: #bdcf02;
  background-color: #fc8917;
  background-color: #374151;
}

.tab--algoboard:before {
  background-color: #fc8917;
  background-color: #374151;
}

.platform--algoforms--tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5em;
  width: 100%;
  background: #bdcf02;
  background-color: #374151;
}

.tab--algoforms:before {
  background-color: #374151;
}

.platform--algovault--tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5em;
  width: 100%;
  background: #bdcf02;
  background-color: #152442;
}

.tab--algovault:before {
  background-color: #152442;
}

.platform--card .flex-equal-inner {
  flex-direction: column;
  display: flex;
}

.platform--card .flex-equal-content {
  flex: 1 0 auto;
}

.platform--card--icon {
  height: 100px;
  width: 100px;
}

.platform--title {
  font-weight: bolder;
  font-size: 1.1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.platform--description {
  line-height: 1.6em;
  font-weight: 300;
  color: #556575;
  margin-bottom: 1em;
}

.platform--description {
  line-height: 1.8em;
}

.platform--description p:first-child{
  margin-top: .25em;
}

.platform--card--more {
  border-bottom: 2px solid transparent;
  position: relative;
  padding-bottom: 3px;
  color: #006ba0;
  display: inline-block;
  transition: all .4s;
}

.platform--card .platform--card--more {
  align-items: flex-end;
  margin-top: 1.5em;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 1em;
  height: 1em;
  display: block;
}

@media only screen and (min-width: 415px) {
  .platform--card {
    width: 30%;
  }
  .platforms--title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 960px) {
  .platforms--title {
    font-size: 2.5rem;
  }
}