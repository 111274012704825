.career {
  margin: 0;
  padding: 0;
}
.career--main {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.career--heading {
  text-align: center;
  font-size: 1.5rem;
}
.career--title {
  line-height: 1.1em;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-align: center;
}
.career--content p {
  text-align: center;
}
@media only screen and (min-width: 415px) {
  .career {
    /* min-height: 400px;
    max-width: 1250px;
    width: 100%; */
    margin: 0;
    padding: 0;
  }
  .career--main {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* margin-top: 2rem; */
  }
  .career--title {
    line-height: 1.1rem;
    font-weight: 300;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 2rem;
    text-align: center;
  }
  .career--heading {
    text-align: center;
    font-size: inherit;
  }
  .career--content {
    display: flex;
    flex-direction: column;
  }
  .career--content p {
    text-align: center;
  }
}
@media only screen and (min-width: 960px) {
  .career--heading {
    text-align: center;
    color: #000000;
    font-size: revert;
  }
}