/* Footer */
.footer {
	background-color: #f5f7fa;
	background-color: #ffffff;
  padding: 1.2rem 0;
  margin: 0;
  font-size: 0.8rem;
  color: #000;
  text-align: center;
}
.footer span {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.footer a {
  color: #000000;
  text-decoration: underline
}

/* wider screen */
@media screen and (min-width: 980px) {
  .footer {
    font-size: inherit;
  }
}