ul, li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.header {
  min-height: 70px;
  /* max-height: 80px; */
  min-width: 100%;
  background-color: #000000;
  color: #ffffff;
}
.header--container {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
}
.header--navigation {
  position: fixed;
  background-color: #000000;
  top: 0;
  bottom: 0;
  left: 100%;
  right: -100%;
  z-index: 9999;
  transition: all 1s ease-in-out;
}
.header--navigation.active {
  left: 0;
  right: 0;
  transition: all 1s ease-in-out;
}
.header--logo {
  width: 4rem;
  height: 4rem;
}
.hamburger {
  right: 0;
  cursor: pointer;
  margin-right: .5rem;
  font-size: 2.5rem;
  color: #ffffff;
  background-color: transparent;
  border: none;
}
.header--lists {
  display: flex;
  flex-direction: column;
  z-index: 9999;
  max-height: inherit;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.header--list {
  position: relative;
  width: 100%;
}
/* .header--list.logo--list {
  border-bottom: 2px solid #fff;
} */
.logo--list {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
}
.header--link.active {
  text-decoration: none;
  color: #006ba0;
  border-bottom: 2px solid #006ba0;
}
.header--link {
  color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
  border-bottom: 2px solid #fff;
}
/* .header--link:first-of-type {
  border-top: 2px solid #fff;
} */
.header--link:hover,
.header--link:focus,
.header--link:active {
  color: #006ba0;
}

.header--list:hover .link--dropdown:after,
.header--list:focus .link--dropdown:after,
.header--list:active .link--dropdown:after 
{
  border-bottom: 2px solid #006ba0;
  border-left: 2px solid #006ba0;
}
.link--dropdown:after {
  content: "";
  display: block;
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  -ms-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}
.header--dropdown {
  display: flex;
  flex-direction: column;
  display: none;
  transition: all 1s ease-in-out;
}
.header--dropdown.active {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  transition: all 1s ease-in-out;
}
.dropdown--list {
  /* width: max-content;
  width: 160px;
  padding-top: .5rem;
  padding-bottom: .5rem; */
  border-bottom: 2px solid #fff;
}
.dropdown--link {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 2.5rem;
  /* padding-right: 1.5rem; */
  color: #ffffff;
  display: block;
  width: 100%;
  /* border-bottom: 2px solid #fff; */
}
.dropdown--link.active {
  text-decoration: none;
  color: #006ba0;
}
/* @media only screen and (min-width: 415px) and (max-width: 815px) {} */
@media only screen and (min-width: 600px) {
  .header {
    min-height: auto;
    /* max-height: 80px; */
    min-width: 100%;
    background-color: #000000;
    color: #ffffff;
    box-shadow: 0 2px 4px 0 #000;
    transition: all 1s ease-in-out;
  }
  .header--container {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .header--navigation {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .header--logo {
    width: 4rem;
    height: 4rem;
  }
  .header--lists {
    display: flex;
    flex-direction: row;
    z-index: 9999;
    max-height: inherit;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .header--list {
    display: inline-block;
    position: relative;
  }
  .header--list.logo--list {
    border-bottom: none;
  }
  .header--link {
    box-sizing: border-box;
    display: block;
    border-bottom: 2px solid #006ba0;
    border-color: transparent;
    color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .header--link.active {
    text-decoration: none;
    border-bottom: 2px solid #006ba0;
    box-sizing: border-box;
    color: #006ba0;
  }
  .header--list:hover > .header--link {
    color: #006ba0;
  }
  .logo--mobile {
    display: none;
  }
  .logo--list {
    padding: 0px;
  }
  .logo--list > button {
    display: none;
  }
  .link--dropdown:after {
    content: "";
    display: block;
    position: absolute;
    right: 11px;
    top: calc(50% - 2px);
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    width: 5px;
    height: 5px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    -ms-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
  }
  .header--list:hover .link--dropdown:after {
    color: #006ba0;
    right: 11px;
    top: calc(50% - 2px);
    border-bottom: 2px solid #006ba0;
    border-left: 2px solid #006ba0;
    /* border-top: 2px solid #006ba0;
    border-right: 2px solid #006ba0; */
    /* transform: rotate(-225deg) translateY(-50%); */
  }
  .header--dropdown {
    display: flex;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
  }
  .header--list:hover .header--dropdown {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    margin-top: 0rem;
  }
  .dropdown--list {
    width: max-content;
    width: 160px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 2px solid #fff;
  }
  .dropdown--link {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 0rem;
    padding-right: 0rem;
    color: #ffffff;
    display: block;
    text-align: center;
  }
  .dropdown--link.active {
    color: #006ba0;
  }
}

@media only screen and (min-width: 960px) {
  .header--container {
    width: 80%;
    margin: 0 auto;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .header--navigation {
    position: relative;
    left: 0;
    right: 0;
    width: 80%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .header--list {
    padding-left: 0rem;
    padding-right: 0rem;
    width: max-content;
  }
  .header--list.logo--list {
    border-bottom: none;
    margin-right: 10rem;
  }
  .header--link {
    box-sizing: border-box;
    display: block;
    border-bottom: 2px solid transparent;
    /* border-color: transparent; */
    color: #ffffff;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .header--link.active {
    text-decoration: none;
    border-bottom: 2px solid #006ba0;
    box-sizing: border-box;
    color: #006ba0;
  }
  .header--link.active.link--dropdown:after {
    border-bottom: 2px solid #006ba0;
    border-left: 2px solid #006ba0;
    /* border-bottom: none;
    border-left: none; */
    top: calc(50% - 2px);
    /* border-top: 2px solid #006ba0;
    border-right: 2px solid #006ba0; */
  }
  .logo--link {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .header--logo {
    width: 5rem;
    height: 5rem;
  }
  .link--dropdown:after {
    right: 10px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    width: 10px;
    height: 10px;
  }
  .dropdown--list {
    width: max-content;
    width: 180px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 2px solid #fff;
  }
  .dropdown--link {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #ffffff;
    display: block;
  }
  .dropdown--link {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .dropdown--link.active {
    text-decoration: none;
    color: #006ba0;
  }
}