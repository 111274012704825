.subscription {
  background-color: #f5f7fa;
  color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.subscription--main {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.subscription--heading {
  text-align: center;
  color: #000000;
  font-size: 1.5rem;
}
.subscription--title {
  line-height: 1.1em;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-align: center;
}
.subscription--content p {
  text-align: left;
}
.subscription--container {
  display: flex;
  flex-direction: column;
}
.subscription--validations {
  display: flex;
  justify-content: center;
  /* background-color: #ff0000; */
  color: #ff0000;
  padding: .5rem;
  border-radius: 5px;
}
.subscription--validations ul, .subscription--validations li {
  text-align: left;
  list-style-type: disc;
}
.subscription--item {
  margin: .5rem 0;
  border-radius: 5px;
  width: 100%;
  position: relative;
  text-align: left;
}
.subscription--text {
  width: 100%;
}
.subscription--required:after {
  color: #FF0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.subscription--label {
  color: #000;
  position: relative;
  height: 16px;
  text-align: left;
  font-size: inherit;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02rem;
}
.subscription--input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  font-size: 1.2rem !important;
  line-height: 3rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 10px;
  /* margin-top: 5px; */
  width: 100%; 
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}
.subscription--input.subscription--error {
  border-color: #FF0000;
}
.subscription--button {
  width: 100%;
  /* margin-top: 16px; */
  font-size: 1.2rem !important;
  line-height: 3rem;
  border-radius: 5px;
  border: 1px solid transparent;
  outline: none;
  cursor: not-allowed;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #fef3e7;
  background-color: #000000;
  padding-left: 10px;
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.subscription--button:hover {
  color: #005a87;
}
.button--arrow {
  padding-left: .5rem;
  font-size: inherit;
}
@media only screen and (min-width: 415px) {
  .subscription--container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  /* .subscription--item {
    width: 50%;
  } */
  .subscription--heading {
    text-align: center;
    color: #000000;
    font-size: inherit;
  }
}
@media only screen and (min-width: 960px) {
  .subscription--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .subscription--item {
    margin: .5rem .5rem;
  }
  .subscription--min {
    width: 25%;
  }
  .subscription--mid {
    width: 50%;
  }
  .subscription--heading {
    text-align: center;
    color: #000000;
    font-size: revert;
  }
}