body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* h1, h2, h3, h4, h5 {
  line-height: 1.1em;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
} */
/* .blog-extras-container {
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%);
  box-shadow: var(--card-shadow-4);
  background-color: #fff;
  padding: 1rem;
} */
/* --card-shadow-4: 0 4px 6px 0 rgba(0,0,0,0.2); */
@media only screen and (min-width: 960px) {
  .wrapper--container {
    width: 80%;
    margin: 0 auto;
    font-size: 1.3rem;
    text-align: justify;
  }
}