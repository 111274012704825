.cloud {
  margin: 0;
  padding: 0;
}
.cloud--main {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.cloud--heading {
  text-align: center;
  font-size: 1.5rem;
}
.cloud--title {
  line-height: 1.1em;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-align: center;
}
.cloud--content p {
  text-align: justify;
}
.cloud--list {
  list-style-type: disc;
  list-style: inside;
}
@media only screen and (min-width: 415px) {
  .cloud--heading {
    text-align: center;
    color: #000000;
    font-size: inherit;
  }
}
@media only screen and (min-width: 960px) {
  .cloud--heading {
    text-align: center;
    color: #000000;
    font-size: inherit;
  }
}