.demo {
  margin: 0;
  padding: 0;
}
.demo--main {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* margin-top: 2rem; */
}
.demo--heading {
  text-align: center;
  font-size: 1.5rem;
}
.demo--title {
  line-height: 1.1em;
  font-weight: 300;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  text-align: center;
}
.demo--content p {
  text-align: left;
}
.form--container {
  display: flex;
  flex-direction: column;
}
.form--validations {
  display: flex;
  justify-content: center;
  /* background-color: #ff0000; */
  color: #ff0000;
  padding: .5rem;
  border-radius: 5px;
}
.form--validations ul, .form--validations li {
  text-align: left;
  list-style-type: disc;
}
.form--item {
  margin: .5rem 0;
  border-radius: 5px;
  width: 100%;
  position: relative;
  text-align: left;
}
.form--text {
  width: 100%;
}
.form--required:after {
  color: #FF0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.form--label {
  color: #000;
  position: relative;
  height: 16px;
  text-align: left;
  font-size: inherit;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02rem;
}
.form--input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  line-height: 3rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 10px;
  margin-top: 5px;
  width: 100%; 
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
}
.form--button {
  width: 100%;
  margin-top: 16px;
  font-size: 1.2rem !important;
  line-height: 3rem;
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  cursor: not-allowed;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #fef3e7;
  background-color: #000000;
  padding-left: 10px;
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.form--button:hover {
  color: #005a87;
}
.button--arrow {
  padding-left: .5rem;
  font-size: inherit;
}
@media only screen and (min-width: 415px) {
  .demo {
    margin: 0;
    padding: 0;
  }
  .demo--main {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* margin-top: 2rem; */
  }
  .demo--title {
    line-height: 1.1rem;
    font-weight: 300;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 2rem;
    text-align: center;
  }
  .demo--heading {
    text-align: center;
    font-size: inherit;
  }
  .demo--content {
    display: flex;
    flex-direction: column;
  }
  .demo--content p {
    text-align: center;
  }
  .demo--form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5rem;
  }
  .form--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form--item {
    margin: .5rem 0;
    border-radius: 5px;
    width: 45%;
    position: relative;
    text-align: left;
  }  
}
@media only screen and (min-width: 960px) {
  .demo--main {
    font-size: 1rem;
  }
  .demo--heading {
    text-align: center;
    color: #000000;
    font-size: revert;
  }
  .form--button {
    width: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}